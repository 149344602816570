@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* styles.css */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f7fa;
  color: #fff;
}

.landing-logo img {
  width: 150px;
  height: auto;
  margin-right: 10px;
}

/* Style for navbar */
.landing-navbar {
  overflow: hidden;
}

.landing-navbar a {
  float: left;
  display: block;
  color: #1a4993;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  justify-content: center;
}
.landing-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Adjust z-index as needed */
}

.landing-navbar a:hover {
  color: grey;
}

.landing-navbar a.active {
  color: grey;
}

.landing-register-btn {
  background-color: #1a4993;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.landing-content-box {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-white-area {
  width: 45%;
  background-color: white;
}

.landing-green-area {
  width: 55%;
  background-color: #1a4993;
  padding: 80px;
}

.landing-description {
  height: auto;
  color: #fff;
}

.landing-group-image {
  width: 300px;
  height: auto;
  margin-left: 100px;
  max-width: 100%;
  margin: 40px auto;
}

.landing-custom-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 20px;
}

.landing-footer-logo {
  display: flex;
  flex-direction: column;
  height: 180px;
  p {
    margin-bottom: 10px;
  }
}

.landing-description {
  margin-bottom: 10px;
}

.landing-hamburger {
  display: none;
  cursor: pointer;
  color: #000000;
}

.landing-mobile-nav {
  width: 50%;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  gap: 30px;
  position: absolute;
  padding-top: 20px;
  z-index: 30;
  top: 0;
  left: 0;
}

.landing-mobile-nav a {
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.landing-mob-register-btn {
  background-color: #1a4993;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
.help-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.help-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  max-width:900px;
  max-height: 100%;
  font-size: 14px;
}

.help-content h3 {
  margin-top: 0;
}

.help-content p {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.help-content button {
  background-color: #1a4993;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.help-content button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 768px) {
  header {
    display: flex;
    align-items: stretch;
  }

  .landing-logo {
    margin-bottom: 20px;
  }
  .landing-navbar a {
    padding: 6px;
    display: none;
  }
  .landing-hamburger {
    display: block;
    font-size: 30px;
  }
  .landing-register-btn {
    display: none;
  }

  .landing-content-box {
    flex-direction: column;
    height: auto;
    justify-content: center;
  }

  .landing-white-area,
  .landing-green-area {
    width: 100%;
  }

  .landing-green-area {
    padding: 20px;
  }

  .landing-custom-text {
    font-size: 15px;
  }

  .landing-description {
    width: 100%;
    padding: 10px;
  }
}