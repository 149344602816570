body {
  margin: 0;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Inter";
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  body {
    font-size: 15px;
  }
}
@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*::-webkit-scrollbar {
  display: block; /* For Chrome, Safari, and Opera */
  width: 5px;
  height: 5px;
  background: #f0f0f0;
}

*:hover::-webkit-scrollbar {
  display: block; /* For Chrome, Safari, and Opera */
  width: 5px;
  height: 5px;
}

/* Scrollbar styles */
*:hover::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 10%;
  margin-bottom: 10%;
  border-radius: 3px;
}

*:hover::-webkit-scrollbar-thumb {
  background: #d7d7d7;
  border-radius: 3px;
}

*:hover::-webkit-scrollbar-thumb:hover {
  background: #919191;
}

/* Apply styles to the title attribute tooltip */
[tooltip]::after {
  content: attr(title);
  display: block;
  position: absolute;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  z-index: 9999;
  /* Adjust positioning to ensure full visibility */
  bottom: 100%; /* Show above the element */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Show the tooltip on hover */
[tooltip]:hover::after {
  opacity: 1;
  visibility: visible;
}
